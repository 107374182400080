<template>
  <div style="height: 100%;background: #F4F7FF;">
    <van-nav-bar title="确认订单" :fixed="true" :z-index="999" left-arrow @click-left="back"></van-nav-bar>
    <div class="dizi" style="margin-top:46px;">
      <div class="dizi_h1">
        <span class="moren">默认</span>
        <span class="name">{{ shangjia.legalPerson }}</span>
        <span class="phone">{{ shangjia.legalPersonPhone }}</span>
      </div>
      <div class="dizi_h2">
        <span class>{{ shangjia.province }}</span>
        <span class>{{ shangjia.city }}</span>
        <span class>{{ shangjia.district }}</span>
      </div>
      <div class="dizi_h3">{{ shangjia.address }}</div>
    </div>
    <!-- 配件 -->
    <div class="list">
      <div class="shangpin_h1">
        <span>商品</span>
        <span class="yun">包安装</span>
        <span class="yun">免运费</span>
      </div>
      <div class="shangpin_h2" v-for="(item, idx) in list.goods" :key="'a' + idx">
        <div class="left">
          <img class="shangpingtu" :src="item.attrImg" alt />
        </div>
        <div class="left btt_h">
          <div class="biaoti_h1">
            <span>{{ item.title }}</span>
            <span style="margin-left: 5px;" v-if="item.norm">{{ item.norm }}</span>
            <span style="margin-left: 5px;" v-if="item.norm">{{ item.model }}</span>
          </div>
          <div class="shang_mone">￥{{ item.price}} x{{ item.goodsNum }}</div>
        </div>
      </div>
      <div class="shangpin_h3" v-for="(item, index) in list.services" :key="'b' + index">
        <span class="left fuwu">服务</span>
        <span class="left daodian">{{ item.title }}</span>

        <span class="right">￥{{ item.price * (item.disc * 0.1) }} x{{ item.goodsNum }}</span>
        <del class="right" style="margin-right: 5px;color: #999;" v-if="item.disc != 10">￥{{ item.price }}</del>
      </div>
    </div>
    <!-- 支付方式 -->
    <div class="zhifu">
      <!-- <div class="zhifu_a">支付方式</div>
      <van-radio-group v-model="radio" direction="horizontal" class="xuanxiang">
        <van-radio checked-color="#07c160" disabled name="2">货到付款</van-radio>
        <van-radio checked-color="#07c160" name="1">在线支付</van-radio>
      </van-radio-group>-->
      <van-radio-group v-model="radio">
        <van-cell-group>
          <van-cell title="在线支付" clickable @click="fanshi('1', '1')">
            <template #right-icon>
              <van-radio name="1" checked-color="#07c160" />
            </template>
          </van-cell>
          <!-- @click="fanshi('0','2')" -->
          <van-cell title="货到付款" clickable>
            <template #right-icon>
              <van-radio name="0" disabled checked-color="#07c160" />
            </template>
          </van-cell>
          <van-cell title="储值卡支付" clickable @click="fanshi('2', '1')" v-if="cname != '秒杀'">
            <template #right-icon>
              <van-radio name="2" checked-color="#07c160" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
    </div>
    <!--优惠券  -->
    <div class="youhui">
      <!-- @click="youhui_show = true" -->
      <van-cell @click="showList = true" is-link title="优惠券" :value="yhvalue"></van-cell>
      <!-- <van-coupon-cell  :coupons="coupons" currency="折" :chosen-coupon="chosenCoupon" @click="showList = true" /> -->
      <van-cell center :title="'积分兑换(当前积分' + (userInfo.score ? userInfo.score : '0') + ')'">
        <template #right-icon>
          <div style="color: red;margin-right: 10px;" v-if="checked">-￥{{ userInfo.score ? ((userInfo.score /
              100).toFixed(2)) : '0'
          }}</div>
          <van-switch v-model="checked" :disabled="userInfo.score == null" @click="jifen" size="20" />
        </template>
      </van-cell>
    </div>
    <!-- 总价格 -->
    <div class="Zjiage">
      <van-cell class="zongjiner" title="商品金额" :value="'￥' + list.orderTotal"></van-cell>
      <van-cell class="zongyunfei" title="总运费" :value="'￥' + expressAmount"></van-cell>
    </div>
    <div style="height: 60px"></div>
    <van-action-sheet v-model="youhui_show" title="标题">
      <div class="content">内容</div>
    </van-action-sheet>
    <!-- 底部 -->
    <div class="bot">
      <div class="left lse">
        <div>
          应付金额：
          <span v-if="!yhisok">
            <span class="jiner">
              <span class="jin_biao">￥</span>
              <span v-if="checked">{{ list.orderTotal - (userInfo.score / 100).toFixed(2) }}</span>
              <span v-else>{{list.orderTotal.toFixed(2) }}</span>
            </span>
          </span>
          <span v-if="yhisok">
            <span class="jiner">
              <span class="jin_biao">￥</span>
              <em v-if="youhui.type == 1">
                <span v-if="checked">{{ (list.orderTotal - (userInfo.score / 100) - youhui.valueDesc).toFixed(2)
                }}</span>
                <span v-else>{{ (list.orderTotal - youhui.valueDesc).toFixed(2) }}</span>
              </em>
              <em v-if="youhui.type == 2">
                <span v-if="checked">{{ (list.orderTotal - (userInfo.score / 100)) *
                    (youhui.valueDesc / 10).toFixed(2)
                }}</span>
                <span v-else>{{ list.orderTotal * (youhui.valueDesc / 10).toFixed(2) }}</span>
              </em>
              <em v-if="youhui.type == 3">
                <span v-if="checked">{{ (list.orderTotal - (userInfo.score / 100) - list.fuwu).toFixed(2) }}</span>
                <span v-else>{{ (list.orderTotal - list.fuwu).toFixed(2) }}</span>
              </em>
              <em v-if="youhui.type == 4">
                <span v-if="checked">{{ (list.orderTotal - (userInfo.score / 100) - youhui.valueDesc).toFixed(2)
                }}</span>
                <span v-else>{{ (list.orderTotal - youhui.valueDesc).toFixed(2) }}</span>
              </em>
            </span>
          </span>
        </div>
        <div v-if="checked">
          <span class="youhui_a">&#12288;已优惠</span>：
          <span class="jin_biao">￥</span>
          {{ userInfo.score ? ((userInfo.score / 100).toFixed(2)) : '0' }}
        </div>
      </div>
      <div class="right">
        <van-button @click="tijiao" class="bott" color="linear-gradient(to right, #ff6034, #ee0a24)" round v-preventReClick v-if="Aswitch == 1" type="info">提交订单</van-button>
        <van-button @click="miaoshatijiao" class="bott" color="linear-gradient(to right, #ff6034, #ee0a24)" round v-preventReClick v-else type="info">提交订单</van-button>
      </div>
    </div>
    <!-- 优惠券列表 -->
    <van-popup v-model="showList" round position="bottom" style="height: 90%; padding-top: 4px;">
      <van-coupon-list :show-exchange-bar="false" :coupons="coupons" :chosen-coupon="chosenCoupon" :disabled-coupons="disabledCoupons" @change="onChange" @click="xuanzhe" />
    </van-popup>
  </div>
</template>

<script>
import userModel from "../../api/user";
import { username } from "../../api/check";
import preventReClick from "../../assets/preventReClick";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
// const coupon = {
//   available: 1,
//   condition: "无使用门槛\n最多优惠12元",
//   reason: "超出100元不可用",
//   value: 150,
//   name: "优惠券名称",
//   startAt: 1489104000,
//   endAt: 1514592000,
//   valueDesc: "1.5",
//   unitDesc: "元"
// };
export default {
  // mixins: [preventBack], //注入
  data() {
    return {
      userInfo: {},
      chosenCoupon: -1,
      coupons: [],
      showList: false,
      disabledCoupons: [],
      user: JSON.parse(localStorage.getItem("userInfo")),
      list: {},
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      radio: "1",
      cname: this.$route.query.cname,
      checked: false,
      zongjia: 100,
      youhui_show: false,
      expressAmount: 0,
      id: "",
      Aswitch: 1,
      status: "",
      xiche: "",
      baoyang: "",
      orderPaySetting: "1",
      ccid: "",
      row: "",
      yhvalue: "",
      yhisok: false,
      greenVip: false,
      isWxMini: "",
      youhui: {},
    };
  },
  methods: {
    onChange(index) {
      if (index != -1) {
        this.yhisok = true;
        this.youhui = this.coupons[index];
        if (this.youhui.type == 1) {
          this.yhvalue = "-￥" + this.youhui.valueDesc.toFixed(2);
        } else if (this.youhui.type == 2) {
          this.yhvalue = this.youhui.valueDesc + "折";
        } else if (this.youhui.type == 3) {
          this.yhvalue = "免服务金额";
        } else if (this.youhui.type == 4) {
          this.yhvalue = "减免" + this.youhui.valueDesc + "配件费";
        }
      } else {
        this.yhisok = false;
        this.youhui = {};
        if (this.coupons.length != 0) {
          this.yhvalue = this.coupons.length + "张可用";
        } else {
          this.yhvalue = "无可用";
        }
      }

      this.showList = false;
      this.chosenCoupon = index;
    },
    //dianji 积分
    jifen() {
      // if(this.checked){
      //   this.list.orderTotal = this.list.orderTotal+ -(this.userInfo.score / 100).toFixed(2)
      // }else
      console.log(this.checked);
    },
    //
    tijiao() {
      var _this = this;
      // if (this.chexin.carNo == "" || this.chexin.carNo == null) {
      //   _this.$toast("请先设置车牌！");
      //   return;
      // }
      // if (
      //   _this.chexin == null ||
      //   _this.chexin.carNo == "" ||
      //   _this.chexin.carNo == null
      // ) {
      //   _this.$toast("请先认证车牌再下单！");
      //   setTimeout(() => {
      //     window.location.href = "/authentication";
      //   }, 1000);

      //   return;
      // }
      var province = this.shangjia.province
        ? this.shangjia.province.toString()
        : "";
      var city = this.shangjia.city ? this.shangjia.city.toString() : "";
      var district = this.shangjia.district
        ? this.shangjia.district.toString()
        : "";
      var address = this.shangjia.address
        ? this.shangjia.address.toString()
        : "";
      var areaDetail = province + city + district + address;
      const data = {
        areaDetail: areaDetail, //维修厂详细地址
        attrImg: this.list.goods[0].attrImg, //商品/服务图片
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : "", //车牌
        couponPrice: 0, //优惠券额度
        garageName: this.shangjia.name, //修理厂名称
        gid: this.shangjia.id, //修理厂id
        goods: this.list.goods, //商品列表
        shareId: this.list.shareId,
        // num: this.list.goods.lenght,
        orderPaySetting: this.orderPaySetting, //支付环境 1:线上 2:线下
        orderRemark: "", //订单备注
        orderTotal: this.list.orderTotal, //商品打折后总价
        payType: this.radio, //支付方式 1:微信 2:余额
        receiver: this.shangjia.legalPerson, //用户名称
        serviceName: this.list.goods[0].title, //商品标题
        services: this.list.services,
        shippingTotal: 0, //运费总价
        tel: this.shangjia.legalPersonPhone,
        uid: this.user.id,
        type: this.list.type,
        couponDOList: this.yhisok ? this.youhui.couponDOList : [],
        positionJd: this.shangjia.positionJd,
        positionWd: this.shangjia.positionWd,
        useCouponStatus: this.yhisok ? 1 : 0, //是否使用优惠券 0:未使用 1:使用
        useScoreTotal: 0, //使用总积分
      };
      var isWxMini = window.__wxjs_environment === "miniprogram";
      console.log(isWxMini);

      userModel
        .xiadanorder(data)
        .then((e) => {
          if (e.code == 200) {
            // loading.clear();
            _this.id = e.data.id;
            _this.status = e.data.status;
            _this.row = e.data.status;
            if (_this.orderPaySetting == 2) {
              _this.$toast("下单成功");
              setTimeout(() => {
                _this.$router.push({
                  path: "order_details",
                  query: {
                    id: e.data.id,
                    status: e.data.status,
                    row: e.data.status,
                    type: e.data.type,
                  },
                });
              }, 1000);
            } else {
              if (e.data.orderTotal == 0) {
                _this.$toast("下单成功");
                setTimeout(() => {
                  _this.$router.push({
                    path: "order_details",
                    query: {
                      id: e.data.id,
                      status: e.data.status,
                      row: e.data.status,
                      type: e.data.type,
                    },
                  });
                }, 800);
              } else {
                if (_this.radio == 1) {
                  var lis = {
                    id: e.data.id,
                    orderNumber: e.data.orderNumber,
                    totalMoney: e.data.orderTotal,
                    type: this.Aswitch,
                    gid: this.shangjia.id,
                  };
                  _this.ccid = e.data.id;
                  var isWxMini = window.__wxjs_environment === "miniprogram";
                  console.log(isWxMini);
                  if (isWxMini) {
                    lis.token = localStorage.getItem("Authorization");
                    lis.ccisok = 1; //1:普通订单，2秒杀订单
                    let payDataStr = JSON.stringify(lis);
                    wx.miniProgram.navigateTo({
                      url:
                        "/pages/payputong/payputong?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
                    });
                  } else {
                    userModel.pay(lis).then((res) => {
                      if (res.code == 200) {
                        if (typeof WeixinJSBridge === "undefined") {
                          // 微信浏览器内置对象。参考微信官方文档
                          if (document.addEventListener) {
                            document.addEventListener(
                              "WeixinJSBridgeReady",
                              _this.onBridgeReady(res.data),
                              false
                            );
                          } else if (document.attachEvent) {
                            document.attachEvent(
                              "WeixinJSBridgeReady",
                              _this.onBridgeReady(res.data)
                            );
                            document.attachEvent(
                              "onWeixinJSBridgeReady",
                              _this.onBridgeReady(res.data)
                            );
                          }
                        } else {
                          console.log("准备调用微信支付");
                          _this.onBridgeReady(res.data);
                        }
                      }
                    });
                  }
                } else if (_this.radio == 2) {
                  _this.ccid = e.data.id;
                  var lis = {
                    id: e.data.id,
                    orderNumber: e.data.orderNumber,
                    totalMoney: e.data.orderTotal,
                    type: _this.Aswitch,
                    gid: _this.shangjia.id,
                    carNo: e.data.carNo,
                    customerId: _this.user.customerId,
                  };
                  userModel.chuzhipay(lis).then((res) => {
                    if (res.code == 200) {
                      _this.$toast("下单成功");
                      setTimeout(() => {
                        window.location.href =
                          "/order_details?id=" +
                          _this.ccid +
                          "&status=" +
                          "1" +
                          "&row=" +
                          "1" +
                          "&type=" +
                          _this.Aswitch;
                      }, 1000);
                    }
                  });
                }
              }
            }
          }
        })
        .catch(() => loading.clear());
    },
    miaoshatijiao() {
      var _this = this;
      if (this.chexin.carNo == "" || this.chexin.carNo == null) {
        _this.$toast("请先设置车牌！");
        return;
      }
      const data = {
        areaDetail:
          this.shangjia.province +
          this.shangjia.city +
          this.shangjia.district +
          this.shangjia.address, //维修厂详细地址
        attrImg: this.list.goods[0].attrImg, //商品/服务图片
        carModel: this.chexin ? this.chexin.carBrand : "", //车型
        carNo: this.chexin ? this.chexin.carNo : "", //车牌
        couponPrice: 0, //优惠券额度
        garageName: this.shangjia.name, //修理厂名称
        gid: this.shangjia.id, //修理厂id
        num: this.list.num,
        shareId: this.list.shareId,
        goodsId: this.list.goodsId,
        orderPaySetting: this.orderPaySetting, //支付环境 1:线上 2:线下
        orderRemark: "", //订单备注
        orderTotal: this.list.orderTotal, //商品打折后总价
        payType: this.radio, //支付方式 1:微信 2:余额
        type: 2,
        receiver: this.shangjia.legalPerson, //用户名称
        serviceName: this.list.goods[0].title, //商品标题
        shippingTotal: 0, //运费总价
        tel: this.shangjia.legalPersonPhone,
        uid: this.user.id,
        positionJd: this.shangjia.positionJd,
        positionWd: this.shangjia.positionWd,
        useCouponStatus: 0, //是否使用优惠券 0:未使用 1:使用
        useScoreTotal: 0, //使用总积分
      };
      console.log(data);
      userModel
        .xiadanorder(data)
        .then((e) => {
          if (e.code == 200) {
            if (_this.orderPaySetting == 2) {
              _this.$toast("下单成功");
              setTimeout(() => {
                _this.$router.push({
                  path: "order_details",
                  query: {
                    id: e.data.id,
                    status: e.data.status,
                    row: e.data.status,
                  },
                });
              }, 800);
            } else {
              if (_this.radio == 1) {
                userModel.seckillOrder().then((re) => {
                  if (re.code == 200) {
                    _this.ccid = re.data.orderId;
                    var lis = {
                      id: re.data.orderId,
                      orderNumber: re.data.orderNumber,
                      totalMoney: re.data.money,
                      type: _this.Aswitch,
                      gid: _this.shangjia.id,
                    };
                    var isWxMini = window.__wxjs_environment === "miniprogram";
                    console.log(isWxMini);
                    if (isWxMini) {
                      lis.token = localStorage.getItem("Authorization");
                      lis.ccisok = 2; //1:普通订单，2秒杀订单
                      let payDataStr = JSON.stringify(lis);
                      wx.miniProgram.navigateTo({
                        url:
                          "/pages/payputong/payputong?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
                      });
                    } else {
                      userModel.pay(lis).then((res) => {
                        if (res.code == 200) {
                          console.log(res);
                          if (typeof WeixinJSBridge === "undefined") {
                            // 微信浏览器内置对象。参考微信官方文档
                            if (document.addEventListener) {
                              document.addEventListener(
                                "WeixinJSBridgeReady",
                                _this.onBridgeReady(res.data),
                                false
                              );
                            } else if (document.attachEvent) {
                              document.attachEvent(
                                "WeixinJSBridgeReady",
                                _this.onBridgeReady(res.data)
                              );
                              document.attachEvent(
                                "onWeixinJSBridgeReady",
                                _this.onBridgeReady(res.data)
                              );
                            }
                          } else {
                            console.log("准备调用微信支付");
                            _this.onBridgeReady(res.data);
                          }
                        }
                      });
                    }
                  }
                });
              } else if (_this.radio == 2) {
                userModel.seckillOrder().then((re) => {
                  if (re.code == 200) {
                    _this.ccid = re.data.orderId;
                    var lis = {
                      id: re.data.orderId,
                      orderNumber: re.data.orderNumber,
                      totalMoney: re.data.money,
                      type: _this.Aswitch,
                      gid: _this.shangjia.id,
                      carNo: re.data.carNo,
                      customerId: _this.user.customerId,
                    };
                    userModel.chuzhipay(lis).then((res) => {
                      if (res.code == 200) {
                        _this.$toast("下单成功");
                        setTimeout(() => {
                          window.location.href =
                            "/order_details?id=" +
                            _this.ccid +
                            "&status=" +
                            "1" +
                            "&row=" +
                            "1" +
                            "&type=" +
                            _this.Aswitch;
                        }, 1000);
                      }
                    });
                  }
                });
              }
            }
          }
        })
        .catch(() => loading.clear());
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: data.appId, // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("下单成功");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                vm.ccid +
                "&status=" +
                "1" +
                "&row=" +
                "1" +
                "&type=" +
                vm.Aswitch;
              // vm.$router.push({
              //   path: "order_details",
              //   query: {
              //     id: vm.id,
              //     status: 1,
              //     row: 1,
              //     type: this.Aswitch
              //   }
              // });
            }, 1000);
          } else {
            vm.$toast("下单失败");
            setTimeout(() => {
              window.location.href =
                "/order_details?id=" +
                vm.ccid +
                "&status=" +
                "0" +
                "&row=" +
                "0" +
                "&type=" +
                vm.Aswitch +
                "&isok=" +
                true;
            }, 1000);
          }
        }
      );
    },
    fanshi(val, cl) {
      console.log("asdasd");
      this.radio = val;
      this.orderPaySetting = cl;
    },
    xuanzhe(index) {
      console.log(index);
    },
    //优惠券列表
    youhuilist() {
      var itemInfos = [];
      console.log(this.xiche);
      if (this.xiche == "xiche") {
        var lis = {
          item: this.list.applyItem,
          amount: this.list.orderTotal,
        };
        itemInfos.push(lis);
      } else {
        this.list.services.forEach((item, i) => {
          var lis = {
            item: item.title,
            amount: item.price * (item.disc * 0.1),
          };
          itemInfos.push(lis);
        });
      }
      var partInfos = [];
      if (this.baoyang == "baoyang") {
        this.list.goods.forEach((item, i) => {
          if (item.goodsId != null) {
            var lis = {
              amount: item.price,
              partBrand: item.partBrand,
              partName: item.partName,
            };
            partInfos.push(lis);
          }
        });
      }
      let data = {
        customerId: this.user.customerId,
        // customerId: 223,
        gid: this.shangjia.id,
        partInfos: partInfos,
        itemInfos: itemInfos,
        orderMoney: this.list.orderTotal,
        type: 1,
      };
      userModel
        .satisfy(data)
        .then((e) => {
          e.data.forEach((item) => {
            var time1 = item.createdDate.replace(/-/g, "/");
            var createdDate = new Date(time1) / 1000;
            var time2 = item.validityDate.replace(/-/g, "/");
            var validityDate = new Date(time2) / 1000;
            var unitDesc = "";
            if (item.type == 1) {
              unitDesc = "元";
            } else if (item.type == 2) {
              unitDesc = "折";
            }
            // if (item.type == 1) {
            if (item.useCill > this.list.orderTotal) {
              var lit = {
                available: item.id,
                discount: 0,
                condition: item.couponName,
                reason: "未满" + item.useCill + "元不可用",
                value: item.type == 3 ? "免费券" : item.discountPrice,
                name: item.couponName,
                startAt: createdDate,
                endAt: validityDate,
                valueDesc: item.type == 3 ? "免费" : item.discountPrice,
                unitDesc: "元",
                type: item.type,
              };
              this.disabledCoupons.push(lit);
            } else {
              var lis = {
                available: item.id,
                discount: 0,
                condition: item.couponName,
                reason: "超出100元不可用",
                value: item.type == 3 ? "免费券" : item.discountPrice,
                name: item.couponName,
                startAt: createdDate,
                endAt: validityDate,
                valueDesc: item.type == 3 ? "免费" : item.discountPrice,
                unitDesc: unitDesc,
                type: item.type,
                couponDOList: [item],
              };
              this.coupons.push(lis);
            }
            // }
          });
          if (this.coupons.length != 0) {
            // this.yhvalue = this.coupons.length + "张可用";
            this.yhisok = true;
            this.youhui = this.coupons[0];
            console.log(this.youhui);
            if (this.youhui.type == 1) {
              this.yhvalue = "-￥" + this.youhui.valueDesc.toFixed(2);
            } else if (this.youhui.type == 2) {
              this.yhvalue = this.youhui.valueDesc + "折";
            } else if (this.youhui.type == 3) {
              this.yhvalue = "免服务金额";
            } else if (this.youhui.type == 4) {
              this.yhvalue = "减免" + this.youhui.valueDesc + "配件费";
            }
            this.chosenCoupon = 0;
          } else {
            this.yhvalue = "无可用";
          }

          console.log();
        })
        .catch();
    },
    getName() {
      username({
        gid: this.shangjia.id,
        uid: this.user.id,
        // uid: 1661
      }).then((res) => {
        console.log(res);
        this.greenVip = res.data.greenVip;
        if (!this.greenVip) {
          for (var i = 0; i < this.list.goods.length; i++) {
            if(this.list.goods[i].sellPrice){
              this.list.goods[i].price = this.list.goods[i].sellPrice;
            }
        
          }
          if(this.list.orderTotalNovip){
            this.list.orderTotal = this.list.orderTotalNovip;
          }
        
        }
      });
    },
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    this.list = JSON.parse(sessionStorage.getItem("xiadan"))
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
    if (this.$route.query.xiche != undefined) {
      this.xiche = this.$route.query.xiche;
    }
    if (this.$route.query.baoyang != undefined) {
      this.baoyang = this.$route.query.baoyang;
    }
    if (this.$route.query.Aswitch != 2) {
      this.youhuilist();
    }
    if (this.$route.query.Aswitch != undefined) {
      this.Aswitch = this.$route.query.Aswitch;
    }
    this.getName();
    // this.list.orderTotal = this.list.orderTotal + this.expressAmount;
  },
};
</script>
<style lang="less" scoped>
.van-nav-bar .van-icon {
  color: #00153e;
}

.shangpin_h1 {
}

.van-nav-bar {
  background: #f4f7ff;
}

body {
  color: #00153e;
  background: #f4f7ff;
}

.list {
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 12px;
  background: #fff;
  padding-bottom: 10px;
}

.shangpin_h1 {
  font-size: 12px;
  line-height: 14px;
  margin: 15px 12px;
}

.shangpin_h2 {
  margin: 5px 12px;
  overflow: hidden;
}

.shangpin_h3 {
  overflow: hidden;
  font-size: 13px;
  color: #666;
  padding: 0 24px 0px 12px;
  margin-top: 10px;
}

.daodian {
  margin-left: 30px;
}

.fuwu {
  margin-left: 30px;
}

.shangpingtu {
  width: 60px;
  height: 60px;
}

.btt_h {
  margin-left: 15px;
  width: 240px;
}

.shang_mone {
  font-size: 13px;
  color: #666;
  text-align: right;
  margin-top: 10px;
  line-height: 14px;
}

.biaoti_h1 {
  font-size: 13px;
  // font-weight: bold;
  height: 34px;
  width: 220px;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 16px;
}

.yun {
  background: #d9314c;
  color: #fff;
  margin-left: 5px;
  font-size: 8px;
  border-radius: 2px;
}

.dizi {
  overflow: hidden;
  margin-bottom: 15px;
  margin: 0 12px;
  background: #fff;
  /* width: 100%; */
  height: 110px;
  border-radius: 5px;
  font-size: 12px;
}

.dizi_h1 {
  margin-top: 12px;
  margin-left: 15px;
}

.dizi_h2 {
  margin-top: 5px;
  margin-left: 15px;
}

.dizi_h3 {
  margin-top: 3px;
  margin-left: 15px;
  font-size: 14px;
}

.moren {
  background: #4169e1;
  color: #fff;
  font-size: 12px;
  padding: 2px 2px;
  border-radius: 4px;
  line-height: 16px;
  margin-right: 5px;
}

.phone {
  color: #666;
  margin-left: 8px;
}

/* 支付 */
.zhifu {
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 12px;
  background: #fff;
  // height: 50px;
}

.zhifu_a {
  margin-left: 12px;
  float: left;
  line-height: 50px;
  font-size: 12px;
}

.xuanxiang {
  margin-top: 15px;
  float: right;
  font-size: 12px;
}

/* 优惠券 */
.content {
  padding: 16px 16px 160px;
}

.youhui {
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 12px;
  background: #fff;
}

/*   <!-- 总价格 --> */
.Zjiage {
  border-radius: 5px;
  overflow: hidden;
  margin: 10px 12px;
  background: #fff;
}

.zongjiner .van-cell__value {
  color: #00153e;
}

.zongyunfei .van-cell__value {
  color: red;
}

/* 底部 */
.bot {
  width: 100%;
  /* padding: 10px; */
  position: fixed;
  height: 60px;
  background: #fff;
  z-index: 9;
  bottom: 0;
}

.lse {
  /* line-height: 60px; */
  font-size: 12px;
  margin-left: 12px;
  margin-top: 10px;
}

.bott {
  height: 40px;
  width: 110px;
  margin-right: 12px;
  margin-top: 10px;
}

.jin_biao {
  font-size: 10px;
}

.jiner {
  color: red;
  font-weight: bold;
  font-size: 18px;
}

.youhui_a {
  color: #3cb371;
}
</style>
<style >
.van-coupon__condition {
  text-align: center;
}

.van-coupon-list__empty p {
  text-align: center;
}
</style>